<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :to="{ name: 'survey-configuration'}"
          >
            <span> {{ $t('ADD_SURVEY') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
          <b-dropdown
            :text="$t('QUESTION')"
            variant="gradient-primary"
            class="ml-1"
          >
            <b-dropdown-item @click="$router.push({ name: 'question-configuration'})">
              <feather-icon icon="PlusCircleIcon" />
              <span class="align-middle ml-50">{{ $t('ADD_QUESTION') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="$router.push({ name: 'question-list'})">
              <feather-icon icon="ListIcon" />
              <span class="align-middle ml-50">{{ $t('QUESTION_LIST') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('SEARCH')"
            />
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refSurveyListTable"
      :items="paginatedSurveys"
      responsive
      :fields="tableColumns"
      primary-key="ID"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDesc"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      class="position-relative"
      @sort-changed="onSortChanged"
    >
      <!-- Column:Name -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ strSubstring(data.item.name,20) }}
          </span>
        </b-media>
      </template>
      <!-- Column:Reference ID -->
      <template #cell(reference_id)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.reference_id }}
          </span>
        </b-media>
      </template>
      <!-- Column:Frequency -->
      <template #cell(frequency)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.frequency }}
          </span>
        </b-media>
      </template>
      <!-- Column:StartDate -->
      <template #cell(start_date)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.start_date) }}
          </span>
        </b-media>
      </template>
      <!-- Column:TotalResponses -->
      <template #cell(total_responses)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.total_responses }}
          </span>
        </b-media>
      </template>

      <!-- Column:Status -->
      <template #cell(survey_status)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.survey_status }}
          </span>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            v-show="isBeforeToday(data.item.end_date)"
            :id="`game-edit-${data.item.ID}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="$router.push({ name: 'survey-configuration-id', params: { id: data.item.ID }})"
          />
          <feather-icon
            v-show="data.item.total_responses>0"
            :id="`game-view-${data.item.ID}-send-icon`"
            icon="BarChart2Icon"
            class="cursor-pointer mr-1"
            size="16"
            @click="$router.push({ name: 'survey-view-id', params: { id: data.item.ID }})"
          />
          <feather-icon
            v-show="isBeforeToday(data.item.end_date)"
            :id="`game-ques-${data.item.ID}-preview-icon`"
            icon="MessageSquareIcon"
            size="16"
            class="cursor-pointer mr-1"
            @click="$router.push({ name: 'survey-question-id', params: { id: data.item.ID }})"
          />
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="navigateSurveyPage(data.item)">
              <feather-icon icon="Link2Icon" />
              <span class="align-middle ml-50">{{ $t('PUBLIC_LINK') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-show="isBeforeToday(data.item.end_date)"
              @click="sendEmailInvitation(data.item)"
            >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">{{ $t('INVITATION_EMAIL') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-show="isBeforeToday(data.item.end_date)"
              @click="sendSmsInvitation(data.item)"
            >
              <feather-icon icon="MessageCircleIcon" />
              <span class="align-middle ml-50">{{ $t('INVITATION_SMS') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-show="isDateUpToToday(data.item.start_date)"
              @click="analyzeSurvey(data.item)"
            >
              <feather-icon icon="SlackIcon" />
              <span class="align-middle ml-50">{{ $t('AI_ANALYZE') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="removeSurvey(data.item)">
              <feather-icon
                icon="Trash2Icon"
              />
              <span class="align-middle ml-50">{{ $t('BUTTON.DELETE') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalSurveys"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow, BCol, BFormInput, BTable,
  BMedia, BButton, BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import constants from '@/constants/static.json'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import { isBeforeToday, isDateUpToToday } from '@/constants/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BPagination,
    BButton,
    vSelect,
    BDropdown,
    BDropdownItem,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      encryptedDefaultId: constants.ENCRYPTED_DEFAULT_ID,
      searchQuery: '',
      sortBy: 'ID',
      isSortDesc: true,
      perPage: constants.PER_PAGE,
      perPageOptions: constants.PER_PAGE_OPTIONS,
      currentPage: 1,
      allSurveys: [],
      tableColumns: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'reference_id', label: 'Reference ID', sortable: false },
        { key: 'frequency', label: 'Frequency', sortable: true },
        { key: 'start_date', label: 'Start Date', sortable: true },
        { key: 'total_responses', label: 'Total Responses', sortable: false },
        { key: 'survey_status', label: 'Status', sortable: false },
        { key: 'Actions' },
      ],
    }
  },
  setup() {
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
    } = mixinAlert()

    return {
      isBeforeToday,
      isDateUpToToday,
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
    }
  },
  async created() {
    this.fetchSurveys()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    totalSurveys() {
      return this.allSurveys.length
    },
    dataMeta() {
      const localItemsCount = this.paginatedSurveys.length
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalSurveys,
      }
    },
    filteredSurveys() {
      let data = this.allSurveys || []
      // Filter by search query
      if (this.searchQuery) {
        const queryLowered = this.searchQuery.toLowerCase()
        data = data.filter(survey => (survey.name && survey.name.toLowerCase().includes(queryLowered))
          || (survey.reference_id && survey.reference_id.toLowerCase().includes(queryLowered)))
      }
      // Sort the data
      data = data.sort((a, b) => {
        const aValue = a[this.sortBy]
        const bValue = b[this.sortBy]

        // If sorting by ID, ensure numeric sorting
        if (this.sortBy === 'ID') {
          return this.isSortDesc ? bValue - aValue : aValue - bValue
        }

        // If sorting by start_date or end_date, treat them as dates
        if (this.sortBy === 'start_date' || this.sortBy === 'end_date') {
          const aDate = new Date(aValue)
          const bDate = new Date(bValue)
          return this.isSortDesc ? bDate - aDate : aDate - bDate
        }

        // Otherwise, sort alphabetically
        const aStr = aValue ? aValue.toString().toLowerCase() : ''
        const bStr = bValue ? bValue.toString().toLowerCase() : ''

        if (this.isSortDesc) {
          return aStr < bStr ? 1 : -1
        }
        return aStr > bStr ? 1 : -1
      })
      return data
    },
    paginatedSurveys() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.filteredSurveys.slice(start, end)
    },
  },
  methods: {
    ...mapActions('survey', ['fetchAllSurveys',
      'sendSurveyInvitation',
      'createSurveyAnalyze',
      'deleteSurvey']),
    removeSurvey(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.name}  ${this.$i18n.t('Survey')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.onDeleteSurvey(data)
          }
        })
    },
    analyzeSurvey(data) {
      let message
      if (data.is_analyze) {
        message = `${this.$i18n.t('MESSAGE.RE_ANALYZE_CONFIRMATION')} ?`
      } else {
        message = `${this.$i18n.t('MESSAGE.ANALYZE_CONFIRMATION')} ${data.name}   ${this.$i18n.t('Survey')} ?`
      }
      this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.warningMessage(this.$i18n.t('MESSAGE.ANALYZE_WAIT'))
            this.onAnalyzeSurvey(data)
          }
        })
    },
    sendSmsInvitation(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.INVITATION_SMS_CONFIRMATION')} ${data.name}   ${this.$i18n.t('Survey')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const payload = {
              id: data.ID,
              user_id: 0,
              message_type: 'sms',
            }
            this.sendSurveyInvitation(payload).then(response => {
              if (response) {
                this.successMessage(response.data.message)
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    sendEmailInvitation(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.INVITATION_EMAIL_CONFIRMATION')} ${data.name}   ${this.$i18n.t('Survey')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const payload = {
              id: data.ID,
              user_id: 0,
              message_type: 'email',
            }
            this.sendSurveyInvitation(payload).then(response => {
              if (response) {
                this.successMessage(response.data.message)
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    navigateSurveyPage(data) {
      let link = `${process.env.VUE_APP_HOST}bisevo/survey/${data.encryptID}?userKey=${this.encryptedDefaultId}`
      if (data.survey_status === constants.SURVEY_STATUS[1]) {
        link = `${process.env.VUE_APP_HOST}bisevo/survey-results/${data.encryptID}`
      }
      window.open(link, '_blank')
    },
    onAnalyzeSurvey(data) {
      if (data) {
        this.createSurveyAnalyze(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.ANALYZE_CREATED'))
            // eslint-disable-next-line no-param-reassign
            data.is_analyze = 1
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      }
    },
    onDeleteSurvey(data) {
      if (data) {
        this.deleteSurvey(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.SURVEY_DELETED'))
            this.fetchSurveys()
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      }
    },
    onSortChanged({ sortBy, sortDesc }) {
      this.sortBy = sortBy
      this.isSortDesc = sortDesc
    },
    async fetchSurveys() {
      const payload = { page: this.currentPage }
      try {
        const response = await this.fetchAllSurveys(payload)
        if (response) {
          this.allSurveys = response.allResult
        } else {
          this.allSurveys = []
        }
      } catch (error) {
        this.showErrorMessage()
        this.allSurveys = []
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
